import React from "react"
import { graphql } from "gatsby"
import {
  Typography,
  Grid,
  Box,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  SvgIcon,
} from "@material-ui/core"
import styled from "styled-components"
import BackgroundImage from "gatsby-background-image"
import {
  SiteTitle,
  Section,
  RichText,
  BackButton,
  Copyright,
  MenuDrawer,
} from "../components"
import { dark } from "../theme/colors"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"

const BlendBackground = styled(BackgroundImage)`
  background-blend-mode: color;
`

const FaqPanel = styled(ExpansionPanel)``

export const IntroProgramPage = ({ data }) => {
  const {
    title,
    background,
    description,
    activities,
  } = data.contentfulIntroProgramPage
  const backgrounds = [`linear-gradient(${dark}, ${dark})`, background.fluid]

  return (
    <React.Fragment>
      <SiteTitle title="Intro Programma" />
      <MenuDrawer />

      <Box
        display="flex"
        minHeight="100vh"
        flexDirection="column"
        overflow="hidden"
      >
        {/* Background image */}
        <BlendBackground fluid={backgrounds}>
          <Section
            slant="background"
            slantCenter
            horizontalSpacing={3}
            verticalSpacing={20}
          >
            <BackButton to="../../" color="secondary" />
          </Section>
        </BlendBackground>

        {/* Title Section */}
        <Section horizontalSpacing={3} verticalSpacing={1}>
          <Grid container justify="center">
            <Grid item xs={12} sm={10} md={10} xl={6}>
              <Typography variant="h2" gutterBottom>
                {title}
              </Typography>
            </Grid>
          </Grid>
        </Section>

        {/* Introduction Section */}
        <Section horizontalSpacing={3} verticalSpacing={1}>
          <Grid container justify="center">
            <Grid item xs={12} sm={10} md={10} xl={6}>
              <RichText>{description.json}</RichText>
            </Grid>
          </Grid>
        </Section>

        {/* Questions and Answers */}
        <Section horizontalSpacing={3} verticalSpacing={2}>
          <Grid container justify="center">
            <Grid item xs={12} sm={10} md={10} xl={6}>
              {activities.map((entry) => (
                <Box mb={2} key={entry.day}>
                  <FaqPanel defaultExpanded={!entry.folded}>
                    <ExpansionPanelSummary
                      expandIcon={<ExpandMoreIcon color="primary" />}
                    >
                      <Typography variant="h6">{entry.day}</Typography>
                    </ExpansionPanelSummary>
                    <Box mt={-1}>
                      <ExpansionPanelDetails>
                        <RichText>{entry.program.json}</RichText>
                      </ExpansionPanelDetails>
                    </Box>
                  </FaqPanel>
                </Box>
              ))}
            </Grid>
          </Grid>
        </Section>

        {/* Footer */}
        <Box display="flex" flexGrow={1}></Box>
        <Section slant="dark" slantCenter verticalSpacing={12}></Section>
        <Section color="dark">
          <Copyright mt={0} />
        </Section>
      </Box>
    </React.Fragment>
  )
}

export const query = graphql`
  query getIntroProgramPage {
    contentfulIntroProgramPage {
      title
      description {
        json
      }
      background {
        fluid(maxWidth: 1920, maxHeight: 1080, quality: 80) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      activities {
        day
        folded
        program {
          json
        }
      }
    }
  }
`

export default IntroProgramPage
